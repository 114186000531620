/*

  This file is used to map a county's "slug" to its "corpCode" value
  as well as all other county-specific settings.

  NOTES
  - all slug values MUST be lower case
  - use this example object for new county records and change all XXXX's to
  the appropriate value...

  {
    active: true,
    bannerImageAltText: "Welcome to {{name}} County, Indiana",
    code: "{{countynumber}}",
    corpCode: "{{corpcode}}",
    externalGisUrl: "",
    form11Url: "",
    heroImageAltText: "court house in {{name}} County Indiana",
    lteInstalled: false,
    name: "{{name}}",
    payTaxBillUrl: "", // url | url w/token | InvoiceCloud
    phone: "{{phone}}",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "{{namelower}}county",
    title: "{{name}} County",
  },

*/

export default [
  {
    active: true,
    bannerImageAltText: "Welcome to Allen County, Indiana",
    code: "02",
    corpCode: "aln",
    externalGisUrl: "https://www.acimap.us/viewer.html",
    form11Url: "https://www.acimap.us/website/form11/{{StateKey}}.pdf",
    heroImageAltText: "court house in Allen county Indiana",
    lteInstalled: true,
    name: "Allen",
    payTaxBillUrl: "InvoiceCloud",
    phone: "260-449-7693",
    photoUrlPrefix: "https://www.acimap.us/website/AssessorPhotos/",
    prcUrl: "https://www.acimap.us/website/prc/{{UnformattedStateKey}}.pdf",
    slug: "allencounty",
    title: "Allen County",
    mapping: {
      arcgis: {
        rest_api_url: "https://gis1.acimap.us/imapweb/rest/",
        querylayer_url:
          "https://gis.acimap.us/projectservicesadmin/rest/services/PATI/QueryLayers/MapServer/2/query",
      },
    },
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Benton County, Indiana",
    code: "4",
    corpCode: "ben",
    externalGisUrl: "https://bentonin.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in Benton county Indiana",
    lteInstalled: false,
    name: "Benton",
    payTaxBillUrl: "https://www.govpaynow.com/gps/user/cyg/welcome",
    phone: "765-884-1070",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "bentoncounty",
    title: "Benton County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Clark County, Indiana",
    code: "10",
    corpCode: "cc",
    externalGisUrl: "https://clarkin.elevatemaps.io",
    form11Url: "",
    heroImageAltText: "court house in Clark county Indiana",
    lteInstalled: true,
    name: "Clark",
    payTaxBillUrl: "https://www.clarkcountyin.net/",
    phone: "812-285-6205",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "clarkcounty",
    title: "Clark County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Clay County, Indiana",
    code: "11",
    corpCode: "cla",
    externalGisUrl: "https://clayin.wthgis.com",
    form11Url: "",
    heroImageAltText: "court house in Clay county Indiana",
    lteInstalled: false,
    name: "Clay",
    payTaxBillUrl:
      "https://govpayments.com/in_clay/item/{{TaxType}}/{{UnformattedStateKey}}",
    phone: "812-448-9009",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "claycounty",
    title: "Clay County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Daviess County, Indiana",
    code: "14",
    corpCode: "dav",
    externalGisUrl: "https://daviessin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Daviess County Indiana",
    lteInstalled: false,
    name: "Daviess",
    payTaxBillUrl: "https://www.govtechtaxpro.com/",
    phone: "812-254-8677",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "daviesscounty",
    title: "Daviess County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Dearborn County, Indiana",
    code: "15",
    corpCode: "dea",
    externalGisUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=267&LayerID=3292&PageTypeID=2&PageID=1830",
    form11Url: "",
    heroImageAltText: "court house in Dearborn county Indiana",
    lteInstalled: false,
    name: "Dearborn",
    payTaxBillUrl: "https://www.dearborn-indiana-tax.com/",
    phone: "812-537-8811",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "dearborncounty",
    title: "Dearborn County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to DeKalb County, Indiana",
    code: "17",
    corpCode: "dek",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=DekalbCountyIN",
    form11Url: "",
    heroImageAltText: "court house in DeKalb county Indiana",
    lteInstalled: true,
    name: "DeKalb",
    payTaxBillUrl: "InvoiceCloud",
    phone: "260-925-2712",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "dekalbcounty",
    title: "DeKalb County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Fayette County, Indiana",
    code: "21",
    corpCode: "fay",
    externalGisUrl: "https://fayettein.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Fayette county Indiana",
    lteInstalled: false,
    name: "Fayette",
    payTaxBillUrl:
      "https://billpay.forte.net/fayettetreasurerweb1/my-account/{{DuplicateNumber}}",
    phone: "765-825-8987",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "fayettecounty",
    title: "Fayette County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Floyd County, Indiana",
    code: "22",
    corpCode: "flo",
    externalGisUrl: "https://floydin.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in Floyd County Indiana",
    lteInstalled: false,
    name: "Floyd",
    payTaxBillUrl:
      "https://www.govtechtaxpro.com/parceldetail.php?idCounty=141",
    phone: "812-948-5477",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "floydcounty",
    title: "Floyd County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Fountain County, Indiana",
    code: "23",
    corpCode: "fou",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=FountainCountyIN",
    form11Url: "",
    heroImageAltText: "court house in Fountain county Indiana",
    lteInstalled: false,
    name: "Fountain",
    payTaxBillUrl:
      "https://billpay.forte.net/fountaincointreasurer/my-account/{{DuplicateNumber}}",
    phone: "765-793-3691",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "fountaincounty",
    title: "Fountain County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Franklin County, Indiana",
    code: "24",
    corpCode: "fra",
    externalGisUrl: "https://franklinin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Franklin county Indiana",
    lteInstalled: false,
    name: "Franklin",
    payTaxBillUrl:
      "https://billpay.forte.net/franklincointax/my-account/{{DuplicateNumber}}",
    phone: "765-647-4631",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "franklincounty",
    title: "Franklin County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Fulton County, Indiana",
    code: "25",
    corpCode: "ful",
    externalGisUrl: "https://fultonin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Fulton county Indiana",
    lteInstalled: true,
    name: "Fulton",
    payTaxBillUrl:
      "https://billpay.forte.net/fultoncointax/my-account/{{DuplicateNumber}}",
    phone: "574-223-2913",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "fultoncounty",
    title: "Fulton County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Gibson County, Indiana",
    code: "26",
    corpCode: "gib",
    externalGisUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=114&LayerID=1283&PageTypeID=1&PageID=928",
    form11Url: "",
    heroImageAltText: "court house in Gibson County Indiana",
    lteInstalled: false,
    name: "Gibson",
    payTaxBillUrl:
      "https://www.govtechtaxpro.com/parceldetail.php?idCounty=15026",
    phone: "812-385-2540",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "gibsoncounty",
    title: "Gibson County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Grant County, Indiana",
    code: "27",
    corpCode: "gra",
    externalGisUrl: "https://grantin.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in Grant County Indiana",
    lteInstalled: false,
    name: "Grant",
    payTaxBillUrl: "InvoiceCloud",
    phone: "765-668-6556",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "grantcounty",
    title: "Grant County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Hendricks County, Indiana",
    code: "32",
    corpCode: "hen",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=HendricksCountyIN",
    form11Url: "",
    externalGisParcelUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=327&PageTypeID=1&KeyValue={{StateKey}}",
    heroImageAltText: "court house in Hendricks county Indiana",
    lteInstalled: true,
    name: "Hendricks",
    payTaxBillUrl: "InvoiceCloud",
    phone: "317-745-9220",
    photoUrlPrefix: "",
    prcUrl: "https://lowtaxinfo.com/prc/hen/{{StateKey}}.pdf",
    slug: "hendrickscounty",
    title: "Hendricks County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Howard County, Indiana",
    code: "34",
    corpCode: "how",
    externalGisUrl: "https://beacon.schneidercorp.com/",
    form11Url: "",
    heroImageAltText: "court house in Howard county Indiana",
    lteInstalled: false,
    name: "Howard",
    payTaxBillUrl:
      "https://billpay.forte.net/howardcointreasurer/my-account/{{DuplicateNumber}}",
    phone: "765-456-2213",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "howardcounty",
    title: "Howard County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Jackson County, Indiana",
    code: "36",
    corpCode: "jac",
    externalGisUrl: "http://thinkopengis.jackson.in.wthtechnology.com/",
    form11Url: "",
    heroImageAltText: "court house in Jackson county Indiana",
    lteInstalled: true,
    name: "Jackson",
    payTaxBillUrl:
      "https://billpay.forte.net/jacksoncointax/my-account/{{DuplicateNumber}}",
    phone: "812-358-6122",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "jacksoncounty",
    title: "Jackson County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Jennings County, Indiana",
    code: "40",
    corpCode: "jen",
    externalGisUrl: "https://jenningsin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Jennings county Indiana",
    lteInstalled: false,
    name: "Jennings",
    payTaxBillUrl:
      "https://billpay.forte.net/jenningscointax/my-account/{{DuplicateNumber}}",
    phone: "812-352-3060",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "jenningscounty",
    title: "Jennings County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Johnson County, Indiana",
    code: "41",
    corpCode: "joh",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=JohnsonCountyIN",
    form11Url: "",
    heroImageAltText: "court house in Johnson county Indiana",
    lteInstalled: false,
    name: "Johnson",
    payTaxBillUrl:
      "https://billpay.forte.net/johnsoncointaxweb/my-account/{{DuplicateNumber}}",
    phone: "317-346-4330",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "johnsoncounty",
    title: "Johnson County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to LaPorte County, Indiana",
    code: "46",
    corpCode: "lac",
    externalGisUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=205&LayerID=2736&PageTypeID=2&PageID=1527",
    form11Url: "",
    heroImageAltText: "court house in LaPorte County Indiana",
    lteInstalled: false,
    name: "LaPorte",
    payTaxBillUrl:
      "https://billpay.forte.net/laportecointreasurer/my-account/{{DuplicateNumber}}",
    phone: "219-326-6808",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "laportecounty",
    title: "LaPorte County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Monroe County, Indiana",
    code: "53",
    corpCode: "mnr",
    externalGisUrl: "https://monroein.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in Monroe county Indiana",
    lteInstalled: false,
    name: "Monroe",
    payTaxBillUrl: "InvoiceCloud",
    phone: "812-349-2530",
    photoUrlPrefix: "https://lowtaxinfo.com/photos/monroe/assessorphotos/",
    prcUrl: "",
    slug: "monroecounty",
    title: "Monroe County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Morgan County, Indiana",
    code: "55",
    corpCode: "mor",
    externalGisUrl: "https://morganin.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in Morgan County Indiana",
    lteInstalled: false,
    name: "Morgan",
    payTaxBillUrl:
      "https://enoticesonline.com/index.php/mor?filter_search={{StateKey}}",
    phone: "765-342-1048",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "morgancounty",
    title: "Morgan County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Owen County, Indiana",
    code: "60",
    corpCode: "owe",
    externalGisUrl: "https://owenin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Owen county Indiana",
    lteInstalled: false,
    name: "Owen",
    payTaxBillUrl: "https://www.govtechtaxpro.com/",
    phone: "812-829-5011",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "owencounty",
    title: "Owen County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Perry County, Indiana",
    code: "62",
    corpCode: "per",
    externalGisUrl: "https://perryin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Perry county Indiana",
    lteInstalled: false,
    name: "Perry",
    payTaxBillUrl:
      "https://pay.paygov.us/EndUser/ParcelSearch.aspx?ttid=10146&duplicatenumber={{DuplicateNumber}}",
    phone: "812-547-4816",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "perrycounty",
    title: "Perry County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Porter County, Indiana",
    code: "64",
    corpCode: "por",
    externalGisUrl:
      "https://portico.mygisonline.com/html5/?viewer=porterin.bv1-p1",
    form11Url: "",
    heroImageAltText: "court house in Porter county Indiana",
    lteInstalled: false,
    name: "Porter",
    payTaxBillUrl:
      "https://enoticesonline.com/index.php/por?filter_search={{StateKey}}",
    phone: "219-465-3470",
    photoUrlPrefix: "https://lowtaxinfo.com/photos/porter/assessorphotos/",
    prcUrl:
      "https://engage.xsoftinc.com/porter/Map/GetParcelList?searchString={{StateKey}}",
    slug: "portercounty",
    title: "Porter County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Pulaski County, Indiana",
    code: "66",
    corpCode: "pul",
    externalGisUrl: "https://pulaskiin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Pulaski county Indiana",
    lteInstalled: false,
    name: "Pulaski",
    payTaxBillUrl:
      "https://pay.paygov.us/EndUser/ParcelSearch.aspx?ttid=14607&duplicatenumber={{DuplicateNumber}}",
    phone: "574-946-3632",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "pulaskicounty",
    title: "Pulaski County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Putnam County, Indiana",
    code: "67",
    corpCode: "put",
    externalGisUrl: "https://putnamin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Putnam county Indiana",
    lteInstalled: false,
    name: "Putnam",
    payTaxBillUrl:
      "https://enoticesonline.com/index.php/put?filter_search={{StateKey}}",
    phone: "765-653-4510",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "putnamcounty",
    title: "Putnam County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Randolph County, Indiana",
    code: "68",
    corpCode: "ran",
    externalGisUrl: "",
    form11Url: "",
    heroImageAltText: "court house in Randolph county Indiana",
    lteInstalled: true,
    name: "Randolph",
    payTaxBillUrl:
      "https://www.govpayments.com/in_randolph/item/{{TaxType}}/{{UnformattedStateKey}}",
    phone: "765-584-2427",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "randolphcounty",
    title: "Randolph County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Rush County, Indiana",
    code: "70",
    corpCode: "rus",
    externalGisUrl: "",
    form11Url: "",
    heroImageAltText: "court house in Rush County Indiana",
    lteInstalled: true,
    name: "Rush",
    payTaxBillUrl:
      "https://billpay.forte.net/rushcotreasurer/my-account/{{DuplicateNumber}}",
    phone: "765-932-2386",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "rushcounty",
    title: "Rush County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Scott County, Indiana",
    code: "72",
    corpCode: "sco",
    externalGisUrl: "https://scottin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Scott County Indiana",
    lteInstalled: false,
    name: "Scott",
    payTaxBillUrl: "https://payments.municipay.com/in_scott",
    phone: "812-752-8414",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "scottcounty",
    title: "Scott County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Spencer County, Indiana",
    code: "74",
    corpCode: "spe",
    externalGisUrl: "https://spencerin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Spencer County Indiana",
    lteInstalled: false,
    name: "Spencer",
    payTaxBillUrl:
      "https://www.govpayments.com/in_spencer/item/{{TaxType}}/{{UnformattedStateKey}}",
    phone: "812-649-4556",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "spencercounty",
    title: "Spencer County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to St. Joseph County, Indiana",
    code: "71",
    corpCode: "sjc",
    externalGisUrl:
      "http://maps.macog.com/Html5Viewer/Index.html?configBase=http://maps.macog.com/Geocortex/Essentials/Ess443/REST/sites/MACOG_HTML5/viewers/MACOG/virtualdirectory/Resources/Config/Default",
    form11Url: "",
    heroImageAltText: "court house in St. Joseph county Indiana",
    lteInstalled: true,
    name: "St. Joseph",
    payTaxBillUrl:
      "https://enoticesonline.com/index.php/stj?filter_search={{StateKey}}",
    phone: "574-235-9531",
    photoUrlPrefix: "https://lowtaxinfo.com/photos/sjc/assessorphotos/",
    prcUrl:
      "https://engageblob.blob.core.windows.net/stjoseph/pdf/2024/{{StateKey}}.pdf",
    slug: "sjcounty",
    title: "St. Joseph County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Starke County, Indiana",
    code: "75",
    corpCode: "sta",
    externalGisUrl: "https://starkein.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Starke county Indiana",
    lteInstalled: true,
    name: "Starke",
    payTaxBillUrl:
      "https://enoticesonline.com/index.php/srk?filter_search={{StateKey}}",
    phone: "574-772-9101",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "starkecounty",
    title: "Starke County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Steuben County, Indiana",
    code: "76",
    corpCode: "ste",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=SteubenCountyIN",
    form11Url: "",
    heroImageAltText: "court house in Steuben County Indiana",
    lteInstalled: false,
    name: "Steuben",
    payTaxBillUrl:
      "https://billpay.forte.net/steubencointax/my-account/{{DuplicateNumber}}",
    phone: "260-668-1000 x1900",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "steubencounty",
    title: "Steuben County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Sullivan County, Indiana",
    code: "77",
    corpCode: "sul",
    externalGisUrl: "",
    form11Url: "",
    heroImageAltText: "court house in Sullivan county Indiana",
    lteInstalled: false,
    name: "Sullivan",
    payTaxBillUrl:
      "https://billpay.forte.net/sullivancointreasurer/my-account/{{DuplicateNumber}}",
    phone: "812-268-5110",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "sullivancounty",
    title: "Sullivan County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Tipton County, Indiana",
    code: "80",
    corpCode: "tip",
    externalGisUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=77&LayerID=21685&PageTypeID=2&PageID=9239",
    form11Url: "",
    heroImageAltText: "court house in Tipton county Indiana",
    lteInstalled: false,
    name: "Tipton",
    payTaxBillUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=77&LayerID=702&PageTypeID=4&PageID=512&KeyValue={{StateKey}}",
    phone: "765-675-2742",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "tiptoncounty",
    title: "Tipton County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Vermillion County, Indiana",
    code: "83",
    corpCode: "ver",
    externalGisUrl: "http://thinkopengis.vermillion.in.wthtechnology.com/",
    form11Url: "",
    heroImageAltText: "court house in Vermillion county Indiana",
    lteInstalled: false,
    name: "Vermillion",
    payTaxBillUrl:
      "https://billpay.forte.net/vermillioncointax/my-account/{{DuplicateNumber}}",
    phone: "765-492-5360",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "vermillioncounty",
    title: "Vermillion County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Vigo County, Indiana",
    code: "84",
    corpCode: "vig",
    externalGisUrl: "https://beacon.schneidercorp.com/?site=VigoCountyIN",
    form11Url: "",
    heroImageAltText: "court house in Vigo county Indiana",
    lteInstalled: false,
    name: "Vigo",
    payTaxBillUrl: "InvoiceCloud",
    phone: "812-462-3251",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "vigocounty",
    title: "Vigo County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Warrick County, Indiana",
    code: "87",
    corpCode: "wrk",
    externalGisUrl: "https://warrickin.wthgis.com/",
    form11Url: "",
    heroImageAltText: "court house in Warrick county Indiana",
    lteInstalled: false,
    name: "Warrick",
    payTaxBillUrl:
      "https://warrickcounty.gov/warrick-county-treasurer-online-payment?duplicate={{DuplicateNumber}}",
    phone: "812-897-6166",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "warrickcounty",
    title: "Warrick County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to White County, Indiana",
    code: "91",
    corpCode: "wte",
    externalGisUrl: "https://whitein.elevatemaps.io/",
    form11Url: "",
    heroImageAltText: "court house in White county Indiana",
    lteInstalled: false,
    name: "White",
    payTaxBillUrl:
      "https://secure.transaxgateway.com/HostedPaymentForm/HostedPaymentPage.aspx?hash=eHvpjtYnRFt0O7MWYCPm4NiMO4T5p5xdeeJTFcE3rAM%3D",
    phone: "574-583-1516",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "whitecounty",
    title: "White County",
  },
  {
    active: true,
    bannerImageAltText: "Welcome to Whitley County, Indiana",
    code: "92",
    corpCode: "whi",
    externalGisUrl:
      "https://beacon.schneidercorp.com/Application.aspx?AppID=85&LayerID=829&PageTypeID=2&PageID=560",
    form11Url: "",
    heroImageAltText: "court house in Whitley county Indiana",
    lteInstalled: true,
    name: "Whitley",
    payTaxBillUrl:
      "https://billpay.forte.net/whitleycotreasurer/my-account/{{DuplicateNumber}}",
    phone: "260-248-3105",
    photoUrlPrefix: "",
    prcUrl: "",
    slug: "whitleycounty",
    title: "Whitley County",
  },
];
